import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/Layout"
import Hero from "../components/HeroContact"
import Formm from "../components/Formm"
import UniversalLink from "../components/UniversalLink"
import { Container, Row, Col } from 'reactstrap';

const Bathroom = () => {
    const { wpPage } = useStaticQuery(graphql`
    {
        wpPage(slug: {eq: "options"}) {
            socialLinks {
              facebookLink
              instagramLink
              linkedinLink
            }
            adress {
              city
              mail
              phone
              street
            }
          }
    }
  `)
    return (
  <Layout>
      <Hero></Hero>
    <div className="contact-page">
      <Container>
          <Row>
            <Col className="iframe-col" lg="6">
            <iframe src="https://www.google.com/maps/d/embed?mid=1DXT1VwlTdY5-QQ4DwgA0-MgGzNG-E2bw&hl=en" width="600" height="450"  allowfullscreen="" loading="lazy"></iframe>
            </Col>
            <Col lg="6">
              <Formm></Formm>
            </Col>
          </Row>
          <Row className="contact-row">
            <Col lg="4">
              <div className="contact-col">
                <span className="icon-home icons-contact"></span>
                <div>
                  <span className="footer-contact-text">{wpPage.adress.street}</span><br />
                  <span className="footer-contact-text">{wpPage.adress.city}</span>
                </div>
              </div>
            </Col>
            <Col lg="4">
              <div className="contact-col">
                <span className="icon-phone icons-contact"></span><span className="footer-contact-text">TEL: <UniversalLink to={"tel:" + wpPage.adress.phone.trim()}>{wpPage.adress.phone}</UniversalLink></span>
              </div>
            </Col>
            <Col lg="4">
              <div className="contact-col">
                <span className="icon-letter icons-contact"></span><span className="footer-contact-text"><UniversalLink to={"mailto:"+ wpPage.adress.mail}>{wpPage.adress.mail}</UniversalLink></span>
              </div>
            </Col>
          </Row>
    </Container>
    </div>
  </Layout>  
    )
    }

export default Bathroom;
 