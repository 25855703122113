import React from "react"

export default class HeroContact extends React.Component {
    render() {
  return (
    <div className="hero hero-contact" style={{ backgroundImage: `url(/wordpress/wp-content/uploads/2021/07/hero-background.jpg)` }}>
      <div className="hero-box">
        <h2 className="hero-title">Contact</h2>
      </div>
    </div>
           );
        }
    }
  
